.container{
    width: 100%;
    max-height: 50vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
 
}

.doubleInputs{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    justify-content: center;

}

.switchStyle{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}




.loadBannerCTA{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}
