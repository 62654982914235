.containerMobileBannerCTASingle {
  position: relative;
  min-height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #edf2f7;
  margin-top: 5px;
  margin-bottom: 5px;
}

.imgCtaSingle {
  width: 100%;
  object-fit: contain;
}

.ctaSingleTextBox {
  width: 100%;
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;

}

.ctaSingleText {
    color: white;
  font-size: 12px;  
  background-color: black;
  padding: 5px;
  border-radius: 5px;
}
