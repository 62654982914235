.upload-containerBannerCTASingle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  
  .hidden-input {
    display: none;
  }
  
  .upload-label {
    background-color: #4a90e2;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .upload-label:hover {
    background-color: #357ac9;
  }
  
  .image-preview {
    width: 200px;
    height: 200px;
    object-fit: fill;
    border-radius: 10px;
    margin-top: 10px;
    border: 2px solid #ddd;
  }

  .emptyImgCTA{
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  